import { Environment } from "../app/classes/flow/base/Environment";

export const ENVIRONMENT: Environment = {
  PRODUCTION: true,
  ORGANISATION: "Gemeente Emmen Staging",
  API_URL: "https://staging.api.coach.emmengeeftenergie.nl/",
  CLIENT_ID: "4a83e4bb-b9ab-41b5-8e2c-4375270eee6a", //app-registration -> client id
  AUTHORITY: "https://deenergiecoach.b2clogin.com/DeEnergieCoach.onmicrosoft.com/B2C_1_Staging_SignupAndSignin", //url + userflow
  KNOWN_AUTHORITY: "https://DeEnergieCoach.b2clogin.com",

  SOCIALS: {
    SLOGAN: "Emmen geeft energie",
    LINKS: {
      MAIN: "https://emmengeeftenergie.nl/",
      PRIVACY: "https://www.emmengeeftenergie.nl/Beheer/Privacyverklaring/default.aspx",
      DISCLAIMER: "",
    },
  },

  EXTRA_TRANSLATIONS_SUFFIX: [],
  MODULES: ["EMPTY_PDF", "TIME_SLOTS"],
  ALLOWED_SPECIALTIES: [1],
  AREA_OF_OPERATIONS: {
    MATCHES: [],
  },
  CUSTOM_HEADER_LOGOS: [],
  REDIRECTS: [],
};
